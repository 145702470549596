/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@alpinejs/intersect@3.12.2/dist/cdn.min.js
 * - /npm/@alpinejs/collapse@3.12.2/dist/cdn.min.js
 * - /npm/@alpine-collective/toolkit-scroll@1.0.1/dist/cdn.min.js
 * - /npm/@alpinejs/focus@3.12.2/dist/cdn.min.js
 * - /npm/alpinejs@3.12.2/dist/cdn.min.js
 * - /npm/@popperjs/core@2.11.6/dist/umd/popper.min.js
 * - /npm/tippy.js@6.3.7/dist/tippy-bundle.umd.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
